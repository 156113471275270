import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  BrowserModule,
  REMOVE_STYLES_ON_COMPONENT_DESTROY,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InitializerService } from './common/services/initializer.service';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/logo/logo.component';
import { VividSvgIconsComponent } from './components/vivid-svg-icons/vivid-svg-icons.component';

export function initializeApp(initializerService: InitializerService) {
  const host = window.location.host;
  return () => initializerService.initialize(host);
}

@NgModule({
  declarations: [AppComponent, HeaderComponent, LogoComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    VividSvgIconsComponent,
  ],
  providers: [
    InitializerService,
    provideEnvironmentNgxMask(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitializerService],
      multi: true,
    },
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
