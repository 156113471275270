import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITenant } from '@common/models/tenant.model';
import { PhcAppService } from '@common/services/phcapp.service';
import { environment } from '@src/environments/environment';
import { Observable, map } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private readonly httpService: HttpService,
    private readonly phcappService: PhcAppService
  ) {}

  public initialize(host: string): Observable<void> {
    const endpoint = `${environment.tenantApiBaseUrl}/tenant/byhost/${host}`;
    const options = {
      headers: new HttpHeaders({
        ContentType: 'application/json',
      }),
    };

    return this.httpService.get<ITenant>(endpoint, options).pipe(
      map((tenant) => {
        this.phcappService.tenant = tenant;
      })
    );
  }
}
