import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account-management/account-management.module').then(
        (m) => m.AccountManagementModule
      ),
  },
  {
    path: 'manage-account',
    loadChildren: () =>
      import('./modules/practitioner-account/practitioner-account.module').then(
        (m) => m.PractitionerAccountModule
      ),
  },
  {
    path: 'supervisor',
    loadChildren: () =>
      import('./modules/supervisor/supervisor.module').then(
        (m) => m.SupervisorModule
      ),
  },
  { path: '', redirectTo: 'account', pathMatch: 'full' },
  { path: '**', redirectTo: 'account' }, // redirects any undefined routes to defined route
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      canceledNavigationResolution: 'replace',
      paramsInheritanceStrategy: 'always',
      urlUpdateStrategy: 'deferred',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
