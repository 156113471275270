export enum CognitoCodeErrors {
  UsernameExistsException = 'UsernameExistsException',
  UserNotFoundException = 'UserNotFoundException',
  InvalidPasswordException = 'InvalidPasswordException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  LimitExceededException = 'LimitExceededException',
  UserLambdaValidationException = 'UserLambdaValidationException',
}

export enum CognitoAuthErrors {
  CodeMismatchException = 'CodeMismatchException',
}

export enum MFAMethod {
  TOTP = 'TOTP',
  SMS = 'SMS',
  NOMFA = 'NOMFA',
  SMS_MFA = 'SMS_MFA',
  SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
}

export enum WorkforceType {
  Pooled,
  NonPooled,
}

export enum Status {
  Active,
  Inactive,
}

export enum UserRoleEnum {
  admin_staff = 'Admin Staff',
  supervisor = 'Supervisor',
  standard_user = 'Standard User',
}

export class UserRole {
  private value: UserRoleEnum;

  constructor(role: string) {
    this.value = this.parse(role);
  }

  public parse(value: string): UserRoleEnum {
    switch (value.toLowerCase()) {
      case 'admin_staff':
        return UserRoleEnum.admin_staff;
      case 'supervisor':
        return UserRoleEnum.supervisor;
      default:
        return UserRoleEnum.standard_user;
    }
  }

  public get Value() {
    return this.value;
  }
}

export enum SignUpStep {
  DONE = 'DONE',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  COMPLETE_AUTO_SIGN_IN = 'COMPLETE_AUTO_SIGN_IN',
}

export enum SignInStep {
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  CONFIRM_SIGN_IN_WITH_EMAIL_CODE = 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGN_IN_WITH_PASSWORD = 'CONFIRM_SIGN_IN_WITH_PASSWORD',
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  CONFIRM_SIGN_IN_WITH_EMAIL_SETUP = 'CONFIRM_SIGN_IN_WITH_EMAIL_SETUP',
  CONFIRM_SIGN_IN_WITH_FIRST_FACTOR_SELECTION = 'CONFIRM_SIGN_IN_WITH_FIRST_FACTOR_SELECTION',
  CONFIRM_SIGN_IN_WITH_MFA_SELECTION = 'CONFIRM_SIGN_IN_WITH_MFA_SELECTION',
  CONFIRM_SIGN_IN_WITH_MFA_SETUP_SELECTION = 'CONFIRM_SIGN_IN_WITH_MFA_SETUP_SELECTION',
  CONFIRM_SIGN_IN_WITH_TOTP_SETUP = 'CONFIRM_SIGN_IN_WITH_TOTP_SETUP',
  DONE = 'DONE',
  RESET_PASSWORD = 'RESET_PASSWORD',
}
